













import Vue from "vue";
import { dispatch } from "@/store";

export default Vue.extend({
  data() {
    return {
      notification: {} as any,
    };
  },
  methods: {
    getNotification() {
      const { id } = this.$route.params;
      if (id) {
        dispatch.notificationsGet(id).then((res) => {
          this.notification = res;
          this.onAClick();
          if (!res.is_read) {
            dispatch.notificationsMark(id);
          }
        });
      } else {
        this.$router.replace(this.$paths.notifications);
      }
    },
    onAClick() {
      setTimeout(() => {
        document
          .querySelectorAll(".notification-detail .content a")
          .forEach((a) => {
            a.addEventListener("click", (e: any) => {
              e.preventDefault();
              const { href } = e.target;
              if (href.includes("/coupons?")) {
                const code = href.replace(/.*code=(.{8})/g, "$1");
                this.$router.replace("/coupons?coupon_code=" + code);
              } else if (href.includes("/coupons/am?")) {
                const code = href.replace(/.*code=(.{8})/g, "$1");
                this.$router.replace("/coupons/am?coupon_code=" + code);
              } else {
                window.open(href, "_blank");
              }
            });
          });
      }, 100);
    },
  },
  created() {
    this.getNotification();
  },
});
